<script lang="ts">
    import {fade} from "svelte/transition";
    import Header from "./Header.svelte";
    import {Board} from "./board";
    import Keyboard from "./keyboard";
    import Modal from "./Modal.svelte";
    import {getContext, onMount, setContext} from "svelte";
    import Settings from "./settings";
    import {
        Seperator,
        Definition,
        Statistics,
        Distribution,
        Timer,
        Toaster,
        ShareGame,
    } from "./widgets";
    import {
        COLS,
        createLetterStates,
        createNewGame,
        DELAY_INCREMENT,
        getState,
        modeData,
        newSeed,
        PRAISE,
        ROWS,
        seededRandomInt,
        words,
    } from "../utils";
    import {letterStates, mode, settings} from "../stores";

    export let word: string;
    export let stats: Stats;
    export let game: GameState;
    export let toaster: Toaster;

    setContext("toaster", toaster);
    const version = getContext<string>("version");

    // implement transition delay on keys
    const delay = DELAY_INCREMENT * ROWS + 600;

    let showSettings = false;
    let showStats = false;
    let showRefresh = false;

    let board: Board;
    let timer: Timer;

    function submitWord() {
        if (game.board.words[game.guesses].length !== COLS) {
            toaster.pop("Nicht genug Buchstaben");
            board.shake(game.guesses);
        } else if (words.contains(game.board.words[game.guesses])) {
            const state = getState(word, game.board.words[game.guesses]);
            game.board.state[game.guesses] = state;
            state.forEach((e, i) => {
                const ls = $letterStates[game.board.words[game.guesses][i]];
                if (ls === "🔳" || e === "🟩") {
                    $letterStates[game.board.words[game.guesses][i]] = e;
                }
            });
            ++game.guesses;
            if (game.board.words[game.guesses - 1] === word) win();
            else if (game.guesses === ROWS) lose();
        } else {
            toaster.pop("Nicht in der Wortliste");
            board.shake(game.guesses);
        }
    }

    function win() {
        board.bounce(game.guesses - 1);
        game.active = false;
        setTimeout(setShowStatsTrue, delay * 1.4);
        if (!modeData.modes[$mode].historical) {
            ++stats.guesses[game.guesses];
            ++stats.played;
            if ("streak" in stats) {
                stats.streak =
                    modeData.modes[$mode].seed - stats.lastGame > modeData.modes[$mode].unit
                        ? 1
                        : stats.streak + 1;
                if (stats.streak > stats.maxStreak) stats.maxStreak = stats.streak;
            }
            stats.lastGame = modeData.modes[$mode].seed;
            localStorage.setItem(`stats-${$mode}`, JSON.stringify(stats));
        }
    }

    function lose() {
        game.active = false;
        if (!modeData.modes[$mode].historical) {
            ++stats.guesses.fail;
            ++stats.played;
            if ("streak" in stats) stats.streak = 0;
            stats.lastGame = modeData.modes[$mode].seed;
            localStorage.setItem(`stats-${$mode}`, JSON.stringify(stats));
        }
    }

    function concede() {
        showSettings = false;
        setTimeout(setShowStatsTrue, DELAY_INCREMENT);
        lose();
    }

    function reload() {
        modeData.modes[$mode].historical = false;
        modeData.modes[$mode].seed = newSeed($mode);
        game = createNewGame($mode);
        word = words.words[seededRandomInt(0, words.words.length, modeData.modes[$mode].seed)];
        $letterStates = createLetterStates();
        showStats = false;
        showRefresh = false;
        timer.reset($mode);
    }

    function setShowStatsTrue() {
        if (!game.active) showStats = true;
    }

    onMount(() => {
        if (!game.active) setTimeout(setShowStatsTrue, delay);
    });

    //Todo: $: toaster.pop(word);
</script>

<svelte:body on:click={board.hideCtx} on:contextmenu={board.hideCtx}/>

<main class:guesses={game.guesses !== 0} style="--rows: {ROWS}; --cols: {COLS}">
    <Header
            bind:showRefresh
            tutorial={$settings.tutorial === 2}
            on:closeTutPopUp|once={() => ($settings.tutorial = 1)}
            showStats={stats.played > 0 || (modeData.modes[$mode].historical && !game.active)}
            on:stats={() => (showStats = true)}
            on:settings={() => (showSettings = true)}
            on:reload={reload}
    />
    <Board
            bind:this={board}
            bind:value={game.board.words}
            tutorial={$settings.tutorial === 1}
            on:closeTutPopUp|once={() => ($settings.tutorial = 0)}
            board={game.board}
            guesses={game.guesses}
            icon={modeData.modes[$mode].icon}
    />
    <Keyboard
            bind:value={game.board.words[game.guesses === ROWS ? 0 : game.guesses]}
            on:submitWord={submitWord}
            on:esc={() => {
			showStats = false;
			showSettings = false;
		}}
            disabled={!game.active || $settings.tutorial === 3}
    />
</main>

<Modal bind:visible={showStats}>
    {#if modeData.modes[$mode].historical}
        <h2 class="historical">Keine Statistik verfügbar</h2>
    {:else}
        <Statistics data={stats}/>
        <Distribution distribution={stats.guesses} {game}/>
    {/if}
    <Seperator visible={!game.active}>
        <Timer
            slot="1"
            bind:this={timer}
            on:timeup={() => (showRefresh = true)}
            on:reload={reload}
        />
    </Seperator>
    <ShareGame wordNumber={game.wordNumber} />
    {#if !game.active}
        <Definition {word} alternates={2}/>
    {:else}
        <!-- Fade with delay is to prevent a bright red button from appearing as soon as refresh is pressed -->
        <div in:fade={{ delay: 300 }} class="concede" on:click={concede}>give up</div>
    {/if}
</Modal>

<Modal fullscreen={true} bind:visible={showSettings}>
    <Settings state={game}/>
</Modal>


<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: var(--game-width);
    margin: auto;
    position: relative;
  }

  .historical {
    text-align: center;
    margin-top: 10px;
    padding: 0 20px;
    text-transform: uppercase;
  }
</style>
