const it_words = [
    "nas",
    "san",
    "ssd",
    "sas",
    "hdd",
    "www",
    "wan",
    "lan",
    "cpu",
    "ram",
    "rom",
    "dvd",
    "gpu",
    "usv",
    "usb",
    "lcd",
    "tft",
    "led",
    "cmd",
    "bat",
    "txt",
    "png",
    "jpg",
    "cat",
    "web",
    "net",
    "cdn",
    "cms",
    "crm",
    "exe",
    "bin",
    "hex",
    "dec",
    "oct",
    "lun",
    "gif",
    "svg",
    "aux",
    "dos",
    "mic",
    "rdp",
    "ssh",
    "ssl",
    "mac",
    "hub",
    "pop",
    "arp",
    "bgp",
    "gpg",
    "gnu",
    "pwm",
    "pci",
    "php",
    "jdk",
    "jre",
    "css",
    "xml",
    "uml",
    "wav",
    "ttl",
    "tld",
    "dvi",
    "vga",
    "srv",
    "caa",
    "iec",
    "bit",
    "key",
    "seo",
    "tel"
];
const words = {
    "words": it_words,
    "valid": it_words
};
export default words;
