<script lang="ts">
	import { getContext, onMount } from "svelte";

	import { settings } from "../../stores";
	import type { Toaster } from "../widgets";
	import Setting from "./Setting.svelte";

	export let state: GameState;

	const toaster = getContext<Toaster>("toaster");

	let root: HTMLElement;
	onMount(() => {
		root = document.documentElement;
	});
	$: {
		if (root) {
			$settings.dark ? root.classList.remove("light") : root.classList.add("light");
			$settings.colorblind
				? root.classList.add("colorblind")
				: root.classList.remove("colorblind");
			localStorage.setItem("settings", JSON.stringify($settings));
		}
	}
</script>

<div class="outer">
	<div class="settings-top">
		<h3>Einstellungen</h3>

		<Setting type="switch" bind:value={$settings.dark}>
			<span slot="title">Dark Theme</span>
		</Setting>

		<div class="links">
			<ul>
				<li><a href="https://wordlefinder.ixtomix.codes" target="_blank">Du brauchst ein Hilfstool? Klicke hier!</a></li>
				<li>
					<a href="https://svelte.dev">
					Made with Svelte<img alt="Svelte" src="/img/svelte-logo.png" width="20px" />
					</a>
				</li>
			</ul>

			<br><br>


		</div>
	</div>
</div>

<style>
	ul{
		list-style: none;
	}
	ul li{
		margin-top: 10px;
	}
	.outer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.links {
		font-size: var(--fs-medium);
		border-bottom: 1px solid var(--border-primary);
		color: var(--fg-secondary);
		display: flex;
		justify-content: space-between;
	}
	:global(.settings-top > div) {
		padding: 16px 0;
		border-bottom: 1px solid var(--border-primary);
	}
</style>
